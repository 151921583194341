export const heroSlides = [
    {
        src: "../images/building/1.jpg",
    },
    {
        src: "../images/building/2.jpg",
    }
]

export const slides = [
    {
        src: "../images/building/gallery/1.jpg",
    },
    {
        src: "../images/building/gallery/2.jpg",
    },
    {
        src: "../images/building/gallery/3.jpg",
    },
    {
        src: "../images/building/gallery/4.jpg",
    },
    {
        src: "../images/building/gallery/5.jpg",
    },
    {
        src: "../images/building/gallery/6.jpg",
    },
    {
        src: "../images/building/gallery/7.jpg",
    },
    {
        src: "../images/building/gallery/8.jpg",
    },
    {
        src: "../images/building/gallery/9.jpg",
    },
];

export const icons = [
    {
        src: "../images/building/spec/1.svg",
        caption: "‘Excellent’ BREEAM rating when delivered",
    },
    {
        src: "../images/building/spec/1.svg",
        caption: "ISO 14001",
    },
    {
        src: "../images/building/spec/1.svg",
        caption: "Active score platinum",
    },
    {
        src: "../images/building/spec/2.svg",
        caption: "2.7m floor-to-ceiling height",
    },
    {
        src: "../images/building/spec/3.svg",
        caption: "150mm raised floor",
    },
    {
        src: "../images/building/spec/4.svg",
        caption: "4x 13-person lifts",
    },
    {
        src: "../images/building/spec/5.svg",
        caption: "Concierge service",
    },
    {
        src: "../images/building/spec/6.svg",
        caption: "Air conditioning",
    },
    {
        src: "../images/building/spec/7.svg",
        caption: "LED lighting",
    },
    {
        src: "../images/building/spec/8.svg",
        caption: "Basement car parking",
    },
    {
        src: "../images/building/spec/9.svg",
        caption: "115x cycle racks",
    },
    {
        src: "../images/building/spec/10.svg",
        caption: "11x showers",
    },
    {
        src: "../images/building/spec/11.svg",
        caption: "12x Brompton lockers",
    },
    {
        src: "../images/building/spec/12.svg",
        caption: "2x car and 12x scooter charging points",
    },
];

export const id = 'mid';