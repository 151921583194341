import "./location.scss";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Amenities from "../../components/amenties/amenties";
import TravelComponent from "../../components/travel/travel";
import LocationSwiper from "../../components/swipers/hero/swiper";
import LocationContent from "./content/locationContent";
import { useEffect, useLayoutEffect, useState } from "react";
import { locationSlideMobile, locationSlides } from "../../utils/location";

const Location: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);




  return (
    <>
      <div className="hero">
        <div className="contain split-text">
          <div className="gradient"></div>
          <img
            src="../images/location/1.jpg"
            className="hero-image"
            id="heroimage"
            alt="building"
          />
          <h1>Dynamic</h1>
        </div>
        <div className="build-split">
          <div className="contain build-split">
            <h1>location</h1>
            <div className="blue-box">
              <h5>
                <b>London’s largest new public space for a decade</b> <br />
                <br />
                Strand Aldwch, with £22m investment from Westminster City Council, has transformed into a tranquil pedestrian-friendly space at the heart of London measuring more than 75,500 sq ft.
              </h5>
            </div>
          </div>
        </div>

        <div className="center mixed">
          <div className="contain location-swiper">
            <LocationSwiper
              slides={isMobile ? locationSlideMobile : locationSlides }
              id={" mid blue-pag loc"}
              shadow={false}
            />
          </div>
          
        </div>
        <div className="center">
            <div className="text-wrap">
            <h5>
              <b>London’s entertainment centre</b> <br></br>
              <br></br>One Kingsway is in the heart of the West End’s shopping,
              entertainment, culinary and cultural district, home to Covent
              Garden Market and the world renowned Royal Opera House.<br></br>
              <br></br> Chic restaurants serve European cuisines, and the nearby
              theatres draw crowds for plays and musicals. Nearby Michelin Star
              restaurants sit side-by-side with a huge range of places for
              quicker bites.
            </h5> 

          </div><img className="triangle-loc" src="../images/location/triangle.svg" alt="triangle" />
          </div>         

        <div className="contain map">
        <h2 className="mobile">
              Local amenities
            </h2>
          <div className="map-image">
            <Zoom>
              <img
                src="../images/location/map.svg"
                width={"100%"}
                style={{ display: "block" }}
              />
            </Zoom>
          </div>
          <img
            src="../images/location/triangle.png"
            className="triangle-tr-flipped mobile"
          />
          <div className="amen">
            <h2>
              Local <br></br> amenities
            </h2>
            <Amenities />
          </div>
        </div>
        <div className="contain travel-section">
          <div className="travel-wrapper">
            <TravelComponent
              station={"covent garden"}
              ugLines={["Piccadilly"]}
              distance={"8 minutes"}
            />
            <TravelComponent
              station={"holborn"}
              ugLines={["Piccadilly", "Central"]}
              distance={"7 minutes"}
            />
            <TravelComponent
              station={"temple"}
              ugLines={["Circle", "District"]}
              distance={"7 minutes"}
            />
          </div>
          <div className="travel-text">
            <h3 style={{ textTransform: "capitalize" }}>
              <b style={{ fontWeight: "bold" }}>Connected</b>
            </h3>
            <h4>
            Three underground stations within 10 minutes walk served by four different lines and close proximity to Waterloo and National Rail. Access to the Elizabeth line is just one stop away at Tottenham Court Road.
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
