import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import '../swiper.scss';

interface Slide {
    src: string;
}

interface SwiperProps {
    slides: Slide[];
    id: string;
    shadow?: boolean;
    extraClass?: string;
}

const HeroSwiper: React.FC<SwiperProps> = ({ slides, id, shadow=true, extraClass }) => {
    return (
        <>
            <SwiperComponent
                spaceBetween={50}
                className={`${id} ${extraClass}`}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation]}
                navigation={true}
                loop={true}
                centeredSlides={false}
                slidesPerView={1}
                effect={"fade"}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide                       
                        key={index}
                    >
                         {shadow && <div className='gradient'> </div>}
                        <img className="swiper-img-back" src={slide.src} width={'100%'} height={'auto'} alt={`slide ${index + 1}`} />
                    </SwiperSlide>
                ))}
                <div className="swiper-pagination">
                    
                </div>
                
            </SwiperComponent>
        </>
    );  
};

export default HeroSwiper;
