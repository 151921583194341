// src/App.tsx

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home"; // Ensure correct import path
import './scss/global.scss';
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import Building from "./pages/building/building";
import Availability from "./pages/availability/availability";
import Location from "./pages/location/location";
import Contact from "./pages/contact/contact";

const App: React.FC = () => {
    const routes = [
      { path: "/", component: Home },
      { path: "/building", component: Building },
      { path: "/availability", component: Availability },
      { path: "/location", component: Location },
      { path: "/contact", component: Contact },
    ];

    return (
        <>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component />}
                        />
                    ))}
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
};

export default App;
