export const slides = [
  {
    src: "../images/availability/gallery/1.jpg",
  },
  {
    src: "../images/availability/gallery/2.jpg",
  },
  {
    src: "../images/availability/gallery/3.jpg",
  },
];
export const bodySlides = [
  {
    src: "../images/availability/1.jpg",
  },
  {
    src: "../images/availability/2.jpg",
  },
];
