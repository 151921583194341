const FifthTable = () => {
    return (
        <div className="floorplan-table">
                            <h4>Business / Cellular / Meetings</h4>
                            <div className='table'>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Workstations</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>114</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Meeting / Training Rooms</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>9</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Private Offices</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>8</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Phone Pods</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>4</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Touchdown</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Breakout</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Meeting Booths</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>2</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Reception</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Teapoint / Breakout</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Area</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>13,300 SQ FT / 1,236 SQ M</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Area / Person</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>10 SQ M / Person</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
    );
    }
export default FifthTable;