import React from "react";

interface Agent {
    name: string;
    email: string;
    number: string;
}

interface Agents {
    agents: Agent[];
    src: string;
    website?: string;
}

const Agents: React.FC<Agents> = ({ agents, src, website }) => {
    return (
        <>
            <a href={website}>
                <img src={src} alt={"logo"} />
            </a>
            {agents.map((agent, index) => (
                <div className='agents' key={index}>
                    <div className='agent'>
                        <h4>{agent.name}</h4>
                        <a className="mail" href={`mailto:${agent.email}`}>
                            <p>{agent.email}</p>
                        </a>
                        <a href={`tel:${agent.number}`}>
                            <h4>{agent.number}</h4>
                        </a>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Agents;
