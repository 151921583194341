const Amenities = () => {
    let count = 1; // Initialize a count variable

    return (
        <div className="split-table">
            <div className="table">
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Sushi Samba</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Roka</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Radio Rooftop Bar</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Opera Tavern</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Barrafina</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Ivy Grill</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Spring at Somerset House</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Tom's Terrace</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Balthazar</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Champagne and Fromage</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Siam Eatery</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Savoy Grill / Simpsons</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Polpo Covent Garden</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Franco Manca</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Dishoom</h5>
                </div>
            </div>
            <div className="table">
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Hawksmoor</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Busaba</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Delaunay</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Wildwood</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Pregnant Man</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Gaucho</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Birley Sandwich Shop</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Pig & Goose</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Colonel Saab</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Sway Bar</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Bunga Bunga</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Hoxton Holborn</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>Rondo</h5>
                </div>
                <div className="row">
                    <h5>
                        <b>{count++}</b>
                    </h5>
                    <h5>The Ivy</h5>
                </div>
            </div>
        </div>
    );
};

export default Amenities;
