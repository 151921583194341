import { useLayoutEffect } from "react";
import Agents from "../../components/agents/agents";
import { bnp_agents, farebrother_agents } from "../../utils/contact";
import "./contact.scss";

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <>
            <div className='hero contact'>
                <div className="center">
                <div className='contain desktop start'>
                    <div className="center-align">
                        <h1>Make</h1>
                    <div className='offset'>
                        <h1>contact</h1>
                    </div> </div>
                </div></div>
                <div className='contain mobile'>
                    <h1>Make Contact</h1>
                </div>
                <div className='contain agents-wrapper-center'>
                    <div className='agents-wrapper'>
                        <div className="company">
                            <Agents
                                agents={bnp_agents}
                                src='../images/contacts/bnp.svg'
                                website="https://www.realestate.bnpparibas.co.uk"
                            />
                        </div>
                        <div  className="company">
                            <Agents
                                agents={farebrother_agents}
                                src='../images/contacts/farebrother.svg'
                                website="https://www.farebrother.com/"
                            />
                        </div>
                        <div>
                            <a href="https://www.bccap.com/">
                            <img
                                src='../images/contacts/bccap.svg'
                                alt='bccap'
                                />
                                </a>
                        </div>
                    </div>
                </div>
                <div className='contain misrep'>
                    <h5>
                        Misrepresentation Act: The joint agents, for themselves
                        and for the vendors or lessors of this property, whose
                        agents they are, give notice that: These particulars are
                        produced in good faith, but are set out as a general
                        guide only, and do not constitute any part of a
                        contract; No person in the employment of the joint
                        agents has any authority to make or give any
                        representation or warranty whatever in relation to this
                        property; Unless otherwise stated all rents or prices
                        quoted are exclusive of VAT which may be payable in
                        addition.
                    </h5>
                    <img src="../images/contacts/marble.png" alt="marble" className="marble" />
                </div>
            </div>
        </>
    );
};

export default Contact;
