export const locationSlides = [
    { src: "../images/location/gallery/2.jpg" },
    { src: "../images/location/gallery/1.jpg" },
    { src: "../images/location/gallery/3.jpg" },
    { src: "../images/location/gallery/4.jpg" },
    { src: "../images/location/gallery/5.jpg" },
    { src: "../images/location/gallery/6.jpeg" },
    { src: "../images/location/gallery/7.jpg" },
];

export const locationSlideMobile = [
    { src: "../images/location/gallery/mobile/1.png" },
    { src: "../images/location/gallery/mobile/2.png" },
    { src: "../images/location/gallery/mobile/3.png" },
    { src: "../images/location/gallery/mobile/4.png" },
    { src: "../images/location/gallery/mobile/5.png" },
    { src: "../images/location/gallery/mobile/6.png" },
    { src: "../images/location/gallery/mobile/7.png" },
];
