/*import {  } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import LottieAnimation from "lottie-react";
import animation from "../../components/navbar/data.json";
import "./home.scss";
import { motion } from "framer-motion";*/



import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "../../scss/global.scss";
import LottieAnimation from "lottie-react";
import animation from "../../components/navbar/data.json";
import "./home.scss";
import { motion } from "framer-motion";

const Home: React.FC = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (location.hash === '#video') {
            const element = document.getElementById('video');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const checkIsMobile = () => {
            const isMobileDevice = window.innerWidth <= 768;
            setIsMobile(isMobileDevice);
        };
        checkIsMobile();
        const handleResize = () => {
            checkIsMobile();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className='hero'>
                <div className='anim-wrapper'>
                    <LottieAnimation
                        animationData={animation}
                        loop={false}
                        autoPlay={true}
                        width={"100%"}
                        height={"auto"}
                        style={{
                            position: "relative",
                            zIndex: -1,
                        }}
                    />
                    <motion.div animate={
                        {
                            opacity: [0, 1],
                            translateY: [-25, 0],
                            transition: {
                                duration: 1,
                                delay: 4,
                            },
                        }
                    
                    } className="arrow">
                        <img src="../images/arrow.svg" alt="arrow" />
                    </motion.div>
                </div>
                <div className='split-text contain' id="video">
                    <div className="vimeoembed">
                        <iframe src="https://player.vimeo.com/video/991575198?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;muted=1&amp;autoplay=1&amp;loop=1" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="1 Kingsway UHD"></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
                <div className="bigtext">
                    
                </div>
                <div className='home-body'>
                    <h1 className="line1">Available</h1>
                    <h1 className="line2">now</h1>
                    <h3>fourth and fifth floors</h3>
                    <h4>13,300 - 27,042 SQ FT</h4>
                    <div className='home-links'>
                        <Link to={"./building"}>
                            <h4>VIEW THE BUILDING</h4>
                        </Link>
                        <Link to={"./availability"}>
                            <h4>VIEW THE AVAILABILITY</h4>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
