interface TravelProps {
    station: string;
    ugLines: string[];
    distance: string;
    logo?: string;
    walking?: string;
}

const basePath = "../images/location/travel/";

const TravelComponent: React.FC<TravelProps> = ({
    station,
    ugLines,
    distance,
    logo = `${basePath}underground.svg`,
    walking = `${basePath}underground.svg`,
}) => {
    return (
        <>
            <div className='travel'>
                <div className='travel-logo'>
                    <div>
                        <img src={logo} alt='logo' />
                    </div>
                    <div>
                        <h3>{station.toUpperCase()}</h3>
                    </div>
                </div>
                <div className='travel-info'>
                    <div className='ug-lines'>
                        <div className='logo-text'>
                            <div className='img-wrapper'>
                                <img
                                    src={`${basePath}walking.svg`}
                                    alt='underground'
                                />
                            </div>
                            <h4>{distance}</h4>
                        </div>

                        {ugLines.map((line, index) => (
                            <div className='logo-text'>
                                <img
                                    src={`${basePath}${line.toLowerCase()}.svg`}
                                    alt={line}
                                />
                                <h4 key={index}>{line}</h4>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default TravelComponent;
