import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./narbar.scss";
import { motion } from "framer-motion";
import Burger from "./burger";

const Nav: React.FC = () => {
    const location = useLocation();
    const [isHeroImageInView, setIsHeroImageInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsHeroImageInView(entry.isIntersecting);
                });
            },
            { threshold: 0 } // Adjust threshold as needed
        );

        const heroImage = document.getElementById("heroimage");
        if (heroImage) {
            observer.observe(heroImage);
        }

        return () => {
            if (heroImage) {
                observer.unobserve(heroImage);
            }
        };
    }, [location]);

    return (
        <div className='nav-hero'>
            <div
                className={`nav-bar ${
                    location.pathname === "/building" && isHeroImageInView ? "opacity" : ""
                }`}
            >
                <div className='bar-contain'>
                    <Link to={"/"}>
                        <motion.img                         
                            src='../images/spellout.svg'
                            alt='title'
                        />
                    </Link>
                    <Burger />
                </div>
            </div>
        </div>
    );
};
export default Nav;
