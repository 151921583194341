const FourthTable = () => {
    return (
        <div className="floorplan-table">
                            <h4>Business / Cellular / Meetings</h4>
                            <div className='table'>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Fixed Workstations</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>48</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Hot Desks</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>72</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Meeting / Training Rooms</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>12</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Phone Pods</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>10</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Touchdown</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>8</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Breakout / Event</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>3</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Meeting Booths</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>6</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Library / Wellbeing</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Teapoint</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>1</h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>
                                            <b>Area</b>
                                        </h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>
                                            <b>13,743 SQ FT / 1,277 SQ M</b>
                                        </h5>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>
                                        <h5>Area / person</h5>
                                    </div>
                                    <div className='cell'>
                                        <h5>10 SQ M / person</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
    );
    }
    
export default FourthTable;