export const bnp_agents = [
    {
        name: "ROB ROONEY",
        email: "robert.rooney@realestate.bnpparibas",
        number: "07469 403 225",
    },
    {
        name: "BEN RAINBOW",
        email: "ben.rainbow@realestate.bnpparibas",
        number: "07909 487 189"
    }, 
];
export const farebrother_agents  = [
    {
        name: "MARK ANSTEY",
        email: "manstey@farebrother.com",
        number: "07841 684 901",
    },
    {
        name: "CHARLIE THOMPSON",
        email: "cthompson@farebrother.com",
        number: "07971 051 117"
    }
];
