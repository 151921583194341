import { useLayoutEffect } from "react";
import FloorplanComponent from "../../components/floorplan/floorplan";
import AvailabilitySwiper from "../../components/swipers/hero/swiper";
import { bodySlides, slides } from "../../utils/availability";
import "./availability.scss";

const Availability: React.FC = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
        <>
            <div className='hero avail'>
                <div className='contain split-text'>
                    <AvailabilitySwiper id='hero avail-swip' slides={slides} />
                    <h1>Exceptional</h1>
                </div>
                <div className='contain avail-split'>
                    <h1>spaces</h1>
                    <div className='table-box'>
                        <div className='table'>
                            <div className='row'>
                                <div className='cell'>Floor</div>
                                <div className='cell'>sq ft</div>
                                <div className='cell'>sq m</div>
                            </div>
                            <div className='row'>
                                <div className='cell'>Fifth</div>
                                <div className='cell'>13,300</div>
                                <div className='cell'>1,236</div>
                            </div>
                            <div className='row'>
                                <div className='cell'>Fourth</div>
                                <div className='cell'>13,742</div>
                                <div className='cell'>1,277</div>
                            </div>
                            <div className='row'>
                                <div className='cell'>Total</div>
                                <div className='cell'>27,042</div>
                                <div className='cell'>2,513</div>
                            </div>
                        </div>
                        <h4>
                        The two available floors at 1 Kingsway are contiguous. The central core building, providers an efficient floor plate, with natural light at every elevation.
                        </h4>
                    </div>
                </div>
                <FloorplanComponent />
                <div className="contain pad-floor-swiper">
                    <img src="../images/triangle.png" className="triangle-tr desktop" />
                    <AvailabilitySwiper id="mid beige-pag" shadow={false} extraClass="blank-back" slides={bodySlides} />
                </div>
            </div>
        </>
    );
};

export default Availability;
