import { useState } from "react";
import "./floorplan.scss";
import { motion, AnimatePresence } from "framer-motion";
import FourthTable from "./utils/fourth";
import FifthTable from "./utils/fifth";

const FloorplanComponent: React.FC = () => {
  const [floor, setFloor] = useState<string>("4");
  const [floorplan, setFloorplan] = useState<boolean>(true);

  const handleText = () => {
    if (floor === "4") {
      return (
        <>
          {floorplan ? (
            <div className="floorplan-title">
              <h2>
                Fourth <br></br> floor
              </h2>
              <div className="hr"></div>
              <div className="white-text">
                <h4>13,742 SQ FT</h4>
                <h4>1,277 SQ M</h4>
              </div>
            </div>
          ) : (
            <FourthTable />
          )}
          <div className="btns">
            <div
              className={`floorplan-btn ${!floorplan && "inactive"}`}
              onClick={() => setFloorplan(true)}
            >
              {floorplan && (
                <div>
                  <img
                    src="../images/availability/floorplans/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
              <h4>FLOOR PLAN</h4>
            </div>
            <div
              className={`floorplan-btn ${floorplan && "inactive"}`}
              onClick={() => setFloorplan(false)}
            >
              {!floorplan && (
                <div>
                  <img
                    src="../images/availability/floorplans/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
              <h4>SPACE PLAN</h4>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {floorplan ? (
            <div className="floorplan-title">
              <h2>
                Fifth <br></br> floor
              </h2>
              <div className="hr"></div>
              <div className="white-text">
                <h4>13,300 SQ FT</h4>
                <h4>1,236 SQ M</h4>
              </div>
            </div>
          ) : (
            <FifthTable />
          )}
          <div className="btns">
            <div
              className={`floorplan-btn ${!floorplan && "inactive"}`}
              onClick={() => setFloorplan(true)}
            >
              {floorplan && (
                <div>
                  <img
                    src="../images/availability/floorplans/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
              <h4>FLOOR PLAN</h4>
            </div>
            <div
              className={`floorplan-btn ${floorplan && "inactive"}`}
              onClick={() => setFloorplan(false)}
            >
              {!floorplan && (
                <div>
                  <img
                    src="../images/availability/floorplans/arrow.svg"
                    alt="arrow"
                  />
                </div>
              )}
              <h4>SPACE PLAN</h4>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className="floorplan">
      <div className={floor === "4" ? `half` : "half-half"}>
        <div className="contain">
          <div className="selectors">
            <div
              className={`selector ${
                (floor === "4" || floor === "4space") && "active"
              }`}
              onClick={() => {
                setFloor("4");
                setFloorplan(true);
              }}
            >
              <motion.h4 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                Fourth Floor
              </motion.h4>
            </div>
            <div
              className={`selector ${
                (floor === "5" || floor === "5space") && "active"
              }`}
              onClick={() => {
                setFloor("5");
                setFloorplan(true);
              }}
            >
              <motion.h4 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
                Fifth Floor
              </motion.h4>
            </div>
          </div>
        </div>
      </div>
      <div className="contain">
        <div className="flex-wrapper">
          <div className="half-wrapper">
            <div className="mobile">
              <h3>{floor === "4" ? "13,742 SQ FT" : "13,300 SQ FT"}</h3>
              <h3>{floor === "5" ? "1,277 SQ M" : "1,236 SQ M"}</h3>
            </div>
              <motion.img
                key={floorplan ? floor : `${floor}space`}
                className="floorplan-img"
                src={
                  floorplan
                    ? `../images/availability/floorplans/${floor}.svg`
                    : `../images/availability/floorplans/${floor}space.svg`
                }
                alt="floorplan"
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0.5 }}
                transition={{ duration: 0.5 }}
              />
         
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="mobile">
                <div
                  className={`floorplan-btn ${!floorplan && "inactive"}`}
                  onClick={() => setFloorplan(true)}
                >
                  <div>
                    <img src="../images/availability/floorplans/arrow.svg" />
                  </div>

                  <h4>FLOOR PLAN</h4>
                </div>
              </div>
              <div className="mobile">
                <div
                  className={`floorplan-btn ${floorplan && "inactive"}`}
                  onClick={() => setFloorplan(false)}
                >
                  <div>
                    <img src="../images/availability/floorplans/arrow.svg" />
                  </div>

                  <h4>SPACE PLAN</h4>
                </div>
              </div>
            </div>
            <div className="mobile">
              {!floorplan && floor === "4" ? <FourthTable /> : null}
              {!floorplan && floor === "5" ? <FifthTable /> : null}
            </div>
            <div className="key">
              <div className="key-wrapper">
                <img src="../images/availability/floorplans/key.svg" />
                <div>
                  <h4>NOT TO SCALE.</h4>
                  <h4>FOR INDICATIVE PURPOSES ONLY.</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="text-half">{handleText()}</div>
        </div>
      </div>
    </div>
  );
};

export default FloorplanComponent;
