import { motion } from "framer-motion";
import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <>
            <div className="bar-hero">
                <div className="footer-flex bar-contain">
                    <img src="../images/logo.svg" alt="logo" />
                    <a
                        className="download"
                        href="../dl/brochure.pdf"
                        target="_blank"
                    >
                        <h4>Download Brochure</h4>
                    </a>
                </div>
            </div>

            <div className="siren">
                <a href="https://sirendesign.co.uk/">
                    <p>Designed and Developed by Siren</p>
                </a>
            </div>
        </>
    );
};

export default Footer;
